import React from 'react';

import Banner from '@components/banner'

import Img from 'gatsby-image';

export default ({ title, subtitle, assets }) => {
  return (
    <Banner
      assets={assets}
      imageTitle={'aboutBanner'}
    >
      <h1 
        className="h3 font-semibold"
        style={{
          paddingTop: '120px',
          paddingBottom: '120px'
        }}
      >{title}</h1>
    </Banner>
  );
};
