import React from 'react';

export default ({
  manifestoTitle,
  manifestoContent,
  manifestoBackgroundImage,
  assets
}) => {
  const backgroundImage = assets.filter(asset => asset.title === manifestoBackgroundImage)[0];

  return (
    <section className="w-100 position-relative my-5">
      <div className="container py-5 position-relative" style={{ zIndex: 2 }}>
        <div className="col-md-6">
          <h2 className="text-white mb-5">{manifestoTitle}</h2>
          <p className="text-white" style={{ whiteSpace: 'pre-line' }}>
            {manifestoContent}
          </p>
          <p className="font-weight-bold text-white">
            UpFamily<br/>
            Verified Success
          </p>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${backgroundImage.file.url})`,
          backgroundSize: 'cover',
            filter: 'brightness(50%)',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            zIndex: 1
        }}
      ></div>
    </section>
  );
};
