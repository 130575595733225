import React from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import AboutBanner from '@components/aboutBanner';
import ProductLogos from '@components/productLogos';
import AboutUs from '@components/aboutUs';
import ManifestoSection from '@components/manifestoSection';
import MeetTheTeamSection from '@components/meetTheTeamSection';

import '@styles/styles.scss';

export const AboutPage = ({ data }) => {
  const ctf = data.contentfulAboutPage;
  const { 
    title, 
    subtitle,
    aboutUsTitle,
    aboutUsContent,
    aboutUsPullQuote,
    companyManifestoTitle,
    companyManifestoContent,
    companyManifestoBackgroundImage
  } = ctf;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);
  const teamMembers = [...data.allContentfulUpFamilyTeamMember.edges.map(edge => edge.node)]
    .sort((a, b) => {
      return a.order > b.order;
    });

  return (
    <Layout>
      <SEO title="About"/>
      <AboutBanner
        title={title}
        subtitle={subtitle.subtitle}
        assets={assets}
      />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ProductLogos/>
          </div>
        </div>
      </div>
      <AboutUs
        title={aboutUsTitle}
        content={aboutUsContent.aboutUsContent}
        pullQuote={aboutUsPullQuote.aboutUsPullQuote}
        assets={assets}
      />
      <ManifestoSection
        manifestoTitle={companyManifestoTitle}
        manifestoContent={companyManifestoContent.companyManifestoContent}
        manifestoBackgroundImage={companyManifestoBackgroundImage}
        assets={assets}
      />
      <MeetTheTeamSection
        teamMembers={teamMembers}
      />
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutQuery {
    contentfulAboutPage {
      title,
      subtitle {
        subtitle
      }
      aboutUsTitle
      aboutUsContent {
        aboutUsContent
      }
      aboutUsPullQuote {
        aboutUsPullQuote
      }
      companyManifestoTitle
      companyManifestoContent {
        companyManifestoContent
      }
      companyManifestoBackgroundImage
    }
    allContentfulAsset {
      edges {
        node {
          title
          file {
            contentType
            url
            fileName
          }
          fixed {
            height
            width
            src
            srcSet
          }
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          } 
        }
      }
    }
    allContentfulUpFamilyTeamMember {
      edges {
        node {
          name
          jobTitle
          photo {
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            }
          }
          order
        }
      }
    }
  }
`;
