import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {

	const data = useStaticQuery(graphql`
		query {
			logoProjectUp: allContentfulAsset(filter: {title: {eq: "logoProjectUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
			logoCheckUp: allContentfulAsset(filter: {title: {eq: "logoCheckUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
			logoTradeUp: allContentfulAsset(filter: {title: {eq: "logoTradeUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
			logoServiceUp: allContentfulAsset(filter: {title: {eq: "logoServiceUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
			logoWorkUp: allContentfulAsset(filter: {title: {eq: "logoWorkUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
		}
	`);

	const logoProjectUpUrl = data.logoProjectUp.edges[0].node.file.url;
	const logoTradeUpUrl = data.logoTradeUp.edges[0].node.file.url;
	const logoServiceUpUrl = data.logoServiceUp.edges[0].node.file.url;
	const logoWorkUpUrl = data.logoWorkUp.edges[0].node.file.url;
	const logoCheckUpUrl = data.logoCheckUp.edges[0].node.file.url;
	const logos = [ logoTradeUpUrl, logoWorkUpUrl, logoServiceUpUrl, logoCheckUpUrl, logoProjectUpUrl ];

  return (
    <div 
      className="w-100 d-flex justify-content-between p-5"
      style={{
        maxWidth: '100%',
        overflowX: 'scroll',
        whiteSpace: 'nowrap'
      }}
    >
      { logos.map((logo, i) => (
        <img
          key={i}
          src={logo}
          alt="logo"
          className="px-2"
          style={{ minHeight: '37px' }}
        />
      ))}
    </div>
  );
};
