import React from 'react';
import Img from "gatsby-image"

export default ({ teamMembers }) => {
  console.log('teamMembers', teamMembers);
  return (
    <section id="meetTheTeam">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <h2 className="text-center font-semibold">Meet the team</h2>
          </div>
        </div>
        <div className="row flex-center">
          { teamMembers.map((teamMember, i) => (
            <div key={i} className="col-md-4">
              <div className="card border-0">
                <Img
                  fluid={teamMember.photo.fluid}
                  alt={teamMember.photo.title}
                  className="card-img-top"
                />
                <div className="card-body">
                  <h3 className="font-semibold">{teamMember.name}</h3>
                  <p className="font-medium">{teamMember.jobTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
