import React from 'react';
import Img from 'gatsby-image';

export default ({ title, content, pullQuote, assets }) => {
  const about1 = assets.filter(asset => asset.title === 'about1')[0];
  const about2 = assets.filter(asset => asset.title === 'about2')[0];

  const paragraphs = content.split('\n').filter(x => x && x.length);
  const halfway = Math.floor(paragraphs.length/2);

  return (
    <div className="container py-5 my-5" style={{ columnCount: '2' }}>
      <h2 className="font-semibold">{title}</h2>
      <div className="pr-2 pr-md-5">
        {paragraphs.map((paragraph, i) => {
          if (i === halfway) {
            return (
              <React.Fragment>
                <p className="font-medium">{paragraph}</p>
                <Img
                  fluid={about1.fluid}
                  alt={about1.title}
                  className="rounded my-3"
                  style={{ pageBreakInside: 'avoid' }}
                />
                <Img
                  fluid={about2.fluid}
                  alt={about2.title}
                  className="rounded my-3"
                  style={{ pageBreakInside: 'avoid' }}
                />
                <p className="lead">{pullQuote}</p>
              </React.Fragment>
            );
          }

          return (
            <p className="font-medium pr-5">{paragraph}</p>
          );
        })}
      </div>
    </div>
  );
}
